<template>
  <div class="commodityList">
    <frame :top1="top1" :top2="top2">
      <!-- <lists :titles='titles' :flag='flag' :contentC='datas'  @add='refresh()' @del='refresh()'></lists> -->
      <RadioGroup v-model="animal">
        <Radio
          v-for="(item, index) in tabbar"
          :label="item"
          border
          :key="index"
          @change="radioChange()"
        ></Radio>
      </RadioGroup>

      <div class="search">
        <div class="input">
          <div class="text">请输入：</div>
          <Input
            v-model="value14"
            placeholder="请输入商品名称"
            clearable
            style="width: 200px"
          />
        </div>
        <div class="input" v-if="admin_type == 1 || admin_type == 2">
          <div class="text"></div>
          <Form :model="formItem" :label-width="120" v-if="admin_type == 1">
            <FormItem label="商户名称:">
              <Select v-model="formItem.selectshop">
                <Option
                  :value="item.storeid"
                  v-for="(item, index) in selects"
                  :key="index"
                  @click.native="shipOpt(item.storeid)"
                  >{{ item.s_name }}</Option
                >
                <!-- <Option value="shanghai">London</Option> -->
                <!-- <Option value="shenzhen">Sydney</Option> -->
              </Select>
            </FormItem>
          </Form>

          <Form :model="formItem" :label-width="120">
            <FormItem label="店铺名称:">
              <Select v-model="formItem.select" @on-change="storeChange()">
                <Option
                  :value="item.storeid"
                  v-for="(item, index) in selectst"
                  :key="index"
                  >{{ item.s_name }}</Option
                >
              </Select>
            </FormItem>
          </Form>
        </div>
        <div class="input">
          <Button type="error" @click="search()">搜索</Button>
        </div>
      </div>

      <div class="list">
        <Card style="margin-left: 200px; margin-top: 20px">
          <div style="height: 600px">
            <div class="titles">商品列表</div>
            <div class="title">
              <slot></slot>
            </div>
            <div class="content">
              <div id="contents">
                <div class="cont">
                  <table>
                    <tr>
                      <td
                        class="titNa"
                        v-for="(item, index) in titles"
                        :key="index"
                      >
                        {{ item }}
                      </td>
                    </tr>
                    <tr
                      v-for="(item2, index2) in datas"
                      :key="index2"
                      :v-if="flag == 1"
                    >
                      <!-- <td>
                        <CheckboxGroup v-model="social">
                          <Checkbox :label="item2.gid"></Checkbox>
                        </CheckboxGroup>
                      </td>
                      <td>{{ item2.gcode }}</td> -->
                      <td>
                        <img :src="imgUrl + item2.img" alt="" />
                      </td>
                      <td>{{ item2.ng_name }}</td>
                      <td>{{ item2.store_name }}</td>
                      <td>¥{{ item2.selling_price }}</td>
                      <!-- <td>¥{{ item2.is_grounding }}</td> -->

                      <td @click="start(item2.nid, item2.is_show)">
                        <el-switch
                          v-model="item2.is_show == 1 ? true : false"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                        >
                        </el-switch>
                      </td>
                      <td>{{ item2.stock }}</td>
                      <td>{{ item2.number }}</td>
                      <td>{{ item2.is_grounding }}</td>
                      <td class="oper">
                        <div
                          class="com"
                          @click="compile(item2.nid, item2.storeid)"
                        >
                          编辑
                        </div>
                        <div class="del" @click="del(item2.nid)">删除</div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[1, 5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="titlePage"
      >
      </el-pagination>
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";
export default {
  name: "equityList",
  data() {
    return {
      top1: "13-2",
      top2: ["13"],
      titles: [
        "商品图片",
        "商品名称",
        "所属店铺",
        "商品价格",
        "是否上架",
        "商品库存",
        "销量",
        "审核状态",
        "操作",
      ],
      flag: 1,
      currentPage4: 1,
      titlePage: 1,
      currentpage: 1,
      page: 1,
      selectshop: "",
      select: "",
      set: 1,
      pageSize: 10,
      datas: null,
      tabbar: ["全部商品", "已通过", "待审核", "未通过"],
      num: 100,
      animal: "全部商品",
      value14: "",
      formItem: {
        select: "",
        selectshop: "",
      },
      selects: null,
      selectst: null,
      admin_type: "",
      social: [],
      dialogVisible: false,
      imgUrl: "",
      modal1: false,
      id: null,
      SHlist: [],
      state: null,
    };
  },
  components: {
    frame,
  },

  watch: {
    animal(newValue, oldValue) {
      this.cut(newValue);
    },
  },
  methods: {
    // 选择各个状态下的商品
    cut(item) {
      let set = "";
      if (item == "全部商品") {
        set = item;
      } else if (item == "已通过") {
        set = 2;
      } else if (item == "待审核") {
        set = 1;
      } else if (item == "未通过") {
        set = 3;
      } else {
        set = 5;
      }
      this.set = set;
      let token = this.$storage.getLocal("token");
      this.$request
        .numGoodsQueryList({
          token,
          type: this.$storage.getLocal("type"),
          storeid: "",
          ng_name: "",

          page: 1,
          limit: this.pageSize,
          is_grounding: this.set,
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    //选择商户后获取门店列表
    shipOpt(value) {
      let token = this.$storage.getLocal("token");
      this.$request
        .selGoodsStoreName({ token, type: 2, pstoreid: value })
        .then((res) => {
          if (res.code == 0) {
            this.selectst = res.data;
          }
        });
    },
    //选中门店查询商品
    storeChange() {
      this.$request
        .numGoodsQueryList({
          token: this.$storage.getLocal("token"),
          type: this.$storage.getLocal("type"),
          storeid: this.formItem.select,
          ng_name: "",
          page: 1,
          limit: this.pageSize,
          is_grounding: this.animal,
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    //搜索商品
    search() {
      this.$request
        .numGoodsQueryList({
          token: this.$storage.getLocal("token"),
          type: this.$storage.getLocal("type"),
          storeid: this.formItem.select,
          ng_name: this.value14,
          page: this.page,
          limit: this.pageSize,
          is_grounding: this.animal,
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    // 审核商品
    audit(id) {
      //审核
      let token = this.$storage.getLocal("token");
      this.$request.selGoodsReviewDataList({ token, id }).then((res) => {
        if (res.code == 0) {
          this.SHlist = res.data.list;
          this.modal1 = true;
          this.id = id;
          this.state = res.data.is_gtype;
        } else {
          this.$notify({
            title: "警告",
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    //删除
    del(id) {
      let token = this.$storage.getLocal("token");
      this.$request
        .delNumGoodsData({
          nid: id,
          token,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
            });
            this.search();
          } else {
            this.$notify({
              title: "失败",
              message: "删除失败",
              type: "warning",
            });
          }
        });
    },
    //商品上下架
    start(id, start) {
      let token = this.$storage.getLocal("token");
      this.$request
        .setNumGoodsShow({
          token,
          nid: id,
          is_show: start == 2 ? 1 : 2,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "成功",
              message: "成功",
              type: "success",
            });
            this.search();
          } else {
            this.$notify({
              title: "警告",
              message: "失败",
              type: "warning",
            });
          }
        });
    },
    //编辑商品
    examine(id) {
      //查看
      this.dialogVisible = true;
    },
    compile(id) {
      //编辑
      this.$router.push("/addequity?id=" + id);
    },
    // 每页多少条
    handleSizeChange(val) {
      this.pageSize = val;
      this.$request
        .numGoodsQueryList({
          token: this.$storage.getLocal("token"),
          type: this.$storage.getLocal("type"),
          storeid: this.formItem.select,
          ng_name: this.value14,
          page: 1,
          limit: val,
          is_grounding: this.animal,
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
    // 跳转去某一页
    handleCurrentChange(val) {
      this.page = val;
      let token = this.$storage.getLocal("token");
      this.$request
        .numGoodsQueryList({
          token: this.$storage.getLocal("token"),
          type: this.$storage.getLocal("type"),
          storeid: this.formItem.select,
          ng_name: this.value14,
          page: val,
          limit: this.pageSize,
          is_grounding: this.animal,
        })
        .then((res) => {
          if (res.code == 0) {
            this.datas = res.data.data;
            this.titlePage = res.data.count;
          }
        });
    },
  },
  created() {
    //控制浏览器滚动条
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.imgUrl = this.$request.imgUrl2() + "uploads/";
    let token = this.$storage.getLocal("token");

    // 获取商户列表或获取门店列表
    var admin_type = this.$storage.getLocal("type");
    this.admin_type = admin_type;
    if (admin_type == "1") {
      this.$request.selGoodsStoreName({ token, type: 1 }).then((res) => {
        if (res.code == 0) {
          this.selects = res.data;
        }
      });
    } else if (admin_type == "2") {
      this.$request.selGoodsStoreName({ token, type: 2 }).then((res) => {
        if (res.code == 0) {
          this.selectst = res.data;
        }
      });
    }
    // 请求商品列表
    this.$request
      .numGoodsQueryList({
        token,
        type: admin_type,
        storeid: "",
        ng_name: "",

        page: 1,
        limit: this.pageSize,
        is_grounding: this.animal,
      })
      .then((res) => {
        if (res.code == 0) {
          this.datas = res.data.data;
          this.titlePage = res.data.count;
        }
      });
  },
};
</script>

<style scoped="scoped">
.commodityList {
  background-color: rgb(245, 247, 249);
  height: 1000px;
}
.tabbar {
  padding: 20px 0 20px 10px;
  text-align: left;
  margin-top: 40px;
  background-color: white;
  width: 82%;
  margin-left: 15.5%;
}
.search {
  padding-left: 200px;
  display: flex;
  text-align: left;
}

.input {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  margin-left: 50px;
  padding: 10px 0 10px 0;
}

.liubai {
  width: 100%;
  height: 40px;
  background-color: #f3f3f3;
  font-size: 15px;
  text-align: left;
  padding-left: 220px;
  line-height: 40px;
}
img {
  width: 20px;
}
.list {
  margin-top: -20px;
  width: 95%;
  margin-left: 2.5%;
}
table {
  margin-left: 2.5%;
  width: 95%;
  background: #eeeeee;
}

td {
  background-color: #ffffff;
  font-size: 15px;
  height: 30px;
  /* width: 15%; */
}

tr .titNa {
  background-color: #f9fafc;
  font-size: 15px;
  font-weight: 800;
}
.titNa {
  text-align: center;
}
.conBo {
  text-align: center;
}
.oper {
  display: flex;
  justify-content: space-around;
  color: #42b983;
}
.shenhe {
  color: #42b983;
  /* width: 50px;	 */
}
.stte,
.del,
.com {
  margin-left: 10px;
  cursor: pointer;
  line-height: 30px;
}
</style>
